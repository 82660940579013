<template>
  <div class="addresses">
    <fd-select
      v-if="showCountry"
      v-model="address.country"
      class="col-12 sm-col-6 lg-col-4 px-1 mb-2"
      label="Country"
      :options="countryOptions"
      :optionValue="(option) => option"
      selectText="Choose a country"
      :validators="[validator.required]"
      @input="countryChanged"
    >
    </fd-select>
    <fd-select
      v-model="address.state"
      class="col-12 sm-col-6 lg-col-4 px-1 mb-2"
      label="State"
      :options="stateOptions"
      :optionValue="(option) => option"
      :selectText="
        showCountry
          ? isEmpty(address.country)
            ? 'Please select a country first'
            : 'Choose a state'
          : 'Choose a state'
      "
      :disabled="showCountry ? isEmpty(address.country) : false"
      :validators="stateRequired ? [validator.required] : []"
      @input="stateChanged"
    >
    </fd-select>

    <fd-v-select
      v-model="address.city"
      class="col-12 sm-col-6 lg-col-4 px-1 mb-2"
      label="City"
      :options="cityOptions"
      :placeholder="
        isEmpty(address.state)
          ? 'Please select a state first'
          : 'Choose an area'
      "
      :disabled="isEmpty(address.state)"
      :validators="cityRequired ? [validator.required] : []"
      @selected="cityChanged"
      @search="searchCities"
    >
      <template #list-footer>
        <InfiniteLoading @infinite="handleCityInfiniteLoading">
          <span slot="no-more"></span>
        </InfiniteLoading>
      </template>
    </fd-v-select>

    <fd-v-select
      v-if="showArea"
      v-model="address.area"
      class="col-12 sm-col-6 lg-col-4 px-1 mb-2"
      label="Area"
      :options="areaOptions"
      :optionLabel="(option) => `${option.name}, ${option.postalCode}`"
      :placeholder="
        isEmpty(address.city) ? 'Please select a city first' : 'Choose an area'
      "
      :disabled="isEmpty(address.city)"
      :validators="areaRequired ? [validator.required] : []"
      @selected="areaChanged"
      @search="searchAreas"
    >
      <template #list-footer>
        <InfiniteLoading @infinite="handleAreaInfiniteLoading">
          <span slot="no-more"></span>
        </InfiniteLoading>
      </template>
    </fd-v-select>

    <fd-v-select
      v-if="showBuilding"
      v-model="address.building"
      class="col-12 sm-col-6 lg-col-4 px-1 mb-2"
      label="Building"
      :options="buildingOptions"
      :placeholder="
        isEmpty(address.area)
          ? 'Please select an area first'
          : 'Choose a building'
      "
      :disabled="isEmpty(address.area)"
      :validators="buildingRequired ? [validator.required] : []"
      :clearable="true"
      @selected="buildingChanged"
      @search="searchBuildings"
    >
      <InfiniteLoading @infinite="handleBuildingInfiniteLoading">
        <span slot="no-more"></span>
      </InfiniteLoading>
    </fd-v-select>

    <div v-if="showStreet" class="col-12 sm-col-6 px-1 mb-2">
      <label class="label">Street</label>
      <vue-autosuggest
        v-model="address.street"
        :suggestions="streetOptions"
        :input-props="{
          id: 'autosuggest__input',
          placeholder: isEmpty(address.area)
            ? 'Select an area first'
            : 'Enter street name',
          disabled: isEmpty(address.area)
        }"
        @input="streetInput"
        @selected="streetSelected"
      >
        <template #default="{ suggestion }">
          <span>{{ suggestion.item.name }}</span>
        </template>
      </vue-autosuggest>
    </div>

    <div v-if="showGeocode" class="col-12 px-1 mb-2">
      <label class="label">Coordinates</label>
      <div class="mb-2">
        <fd-button
          :disabled="isEmpty(address.area)"
          class="main"
          @click="generateGeocodeFromAddrDets"
        >
          Detect From Address Details
        </fd-button>
        <p v-if="isEmpty(address.area)">
          Requires atleast area is selected to detect
        </p>
      </div>

      <google-geocoder
        class="col-12 sm-col-6 mb-2"
        :lat.sync="address.lat"
        :lng.sync="address.lng"
        :required="geocodeRequired"
      ></google-geocoder>
      <MglMap
        ref="mapboxGl"
        class="col-12 sm-col-6"
        :style="{ height: '400px' }"
        :accessToken="mapGl.accessToken"
        :mapStyle="mapGl.mapStyle"
        :center="lngLat"
        :zoom="16"
      >
        <MglMarker :coordinates.sync="lngLat" color="red" draggable></MglMarker>
      </MglMap>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import InfiniteLoading from "vue-infinite-loading";
import vueAutoSuggest from "@/mixins/vue-autosuggest/mixin";
import { getGeocodes } from "@/modules/Address/api/geocode";

import { MglMap, MglMarker } from "vue-mapbox";

export default {
  components: {
    InfiniteLoading,
    MglMap,
    MglMarker,
    GoogleGeocoder: () => import("@/modules/Address/components/Geocoder")
  },
  mixins: [vueAutoSuggest],
  props: {
    country: {
      type: [Object, String],
      default: ""
    },
    state: {
      type: [Object, String],
      default: ""
    },
    city: {
      type: [Object, String],
      default: ""
    },
    area: {
      type: [Object, String],
      default: ""
    },
    building: {
      type: [Object, String],
      default: ""
    },
    street: {
      type: [String],
      default: ""
    },
    lat: {
      type: [String, Number],
      default: ""
    },
    lng: {
      type: [String, Number],
      default: ""
    },
    isEdit: {
      type: Boolean,
      default: false
    },

    stateRequired: {
      type: Boolean,
      default: true
    },

    cityRequired: {
      type: Boolean,
      default: true
    },
    areaRequired: {
      type: Boolean,
      default: true
    },
    buildingRequired: {
      type: Boolean,
      default: false
    },
    geocodeRequired: {
      type: Boolean,
      default: false
    },
    latLngRequired: {
      type: Boolean,
      default: false
    },
    showArea: {
      type: Boolean,
      default: true
    },
    showBuilding: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: true
    },
    showStreet: {
      type: Boolean,
      default: false
    },
    showGeocode: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      buildingOptions: [],
      areaOptions: [],
      cityOptions: [],
      stateOptions: [],
      countryOptions: [],
      streetOptions: [],
      geocodeOptions: [],

      cityNameSearch: "",
      cityOptionsPagination: {
        current_page: 1,
        total_pages: 1
      },
      areaNameSearch: "",
      areaOptionsPagination: {
        current_page: 1,
        total_pages: 1
      },
      buildingNameSearch: "",
      buildingOptionsPagination: {
        current_page: 1,
        total_pages: 1
      },
      geocodeAddress: "",
      mapGl: {
        accessToken: process.env.VUE_APP_MAPBOX_PK,
        mapStyle: "mapbox://styles/mapbox/streets-v11"
      },

      address: {
        country: "",
        state: "",
        city: "",
        area: "",
        building: "",
        street: "",
        // Geocode
        lat: 0,
        lng: 0
      },

      validator: {
        required: required
      },
      isEmpty
    };
  },
  computed: {
    lngLat: {
      get() {
        return [this.address.lng, this.address.lat];
      },
      set([lng, lat]) {
        this.address.lng = lng;
        this.address.lat = lat;
      }
    }
  },
  watch: {
    address: {
      handler(newVal) {
        this.$emit("update:lat", this.address.lat);
        this.$emit("update:lng", this.address.lng);
        this.$emit("change", newVal);
      },
      deep: true
    },
    lngLat(val) {
      if (this.showGeocode) {
        this.$refs.mapGl.panTo({ center: val });
      }
    }
  },
  created: function () {
    if (this.showGeocode) {
      this.mapbox = require("mapbox-gl");
    }
  },
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);

      this.address.country = isEmpty(this.country) ? "" : this.country;
      this.address.state = isEmpty(this.state) ? "" : this.state;
      this.address.city = isEmpty(this.city) ? "" : this.city;
      this.address.area = isEmpty(this.area) ? "" : this.area;
      this.address.building = isEmpty(this.building) ? "" : this.building;
      this.address.street = isEmpty(this.street) ? "" : this.street;
      this.address.lat = isEmpty(this.lat) ? "" : this.lat;
      this.address.lng = isEmpty(this.lng) ? "" : this.lng;

      if (this.showCountry) {
        await this.getCountries();
      } else {
        await this.getStates();
      }

      if (this.isEdit) {
        await Promise.all([
          this.getStates(),
          this.getCities({ id: this.city.id })
        ]);
        if (this.showArea) {
          await this.getAreas({ id: this.area.id });
        }
        if (this.showBuilding) {
          await this.getBuildings({ id: this.building?.id ?? "" });
        }
      }

      this.$store.commit("setIsLoading", false);
    },
    countryChanged(val) {
      this.$store.commit("setIsLoading", true);
      // Set state,city,area,building,street to empty
      this.address.state = "";
      this.address.city = "";
      this.address.area = "";
      this.address.building = "";
      // Update sync prop
      this.$emit("update:country", val);
      // Query for state option
      this.getStates(val.id).then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    stateChanged(val) {
      this.$store.commit("setIsLoading", true);
      // Set city,area,building,street to empty
      this.address.city = "";
      this.address.area = "";
      this.address.building = "";
      // Update sync prop
      this.$emit("update:state", val);
      // Query for city option
      this.clearCityOptions();
      this.address.state.id = val.id;
      this.getCities().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    searchCities: debounce(async function (search, loading) {
      loading(true);
      this.clearCityOptions();
      this.cityNameSearch = search;
      await this.getCities();
      loading(false);
    }, 300),
    cityChanged(val) {
      this.$store.commit("setIsLoading", true);
      // Set area,building,street to empty
      this.address.area = "";
      this.address.building = "";
      // Update sync prop
      this.$emit("update:city", val);
      // Query for area option
      this.clearAreaOptions();
      this.address.city.id = val.id;
      this.getAreas().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearCityOptions() {
      this.cityNameSearch = "";
      this.cityOptions = [];
      this.cityOptionsPagination.current_page = 1;
      this.cityOptionsPagination.total_pages = 1;
    },
    async handleCityInfiniteLoading(infScrollState) {
      if (
        this.cityOptionsPagination.current_page <
        this.cityOptionsPagination.total_pages
      ) {
        this.cityOptionsPagination.current_page++;

        await this.getCities();
        infScrollState.loaded();
      } else {
        infScrollState.loaded();
        infScrollState.complete();
      }
    },
    searchAreas: debounce(function (search, loading) {
      loading(true);
      this.clearAreaOptions();
      this.areaNameSearch = search;
      this.getAreas().then(() => {
        loading(false);
      });
    }, 300),
    areaChanged(val) {
      if (this.showBuilding) {
        this.$store.commit("setIsLoading", true);
        // Set building,street to empty
        this.address.building = "";
        // Update sync prop
        this.$emit("update:area", val);
        // Query for building option
        this.clearBuildingOptions();
        this.address.area.id = val.id;
        this.getBuildings().then(() => {
          this.$store.commit("setIsLoading", false);
        });
      }
    },
    clearAreaOptions() {
      this.areaNameSearch = "";
      this.areaOptions = [];
      this.areaOptionsPagination.current_page = 1;
      this.areaOptionsPagination.total_pages = 1;
    },
    async handleAreaInfiniteLoading(infScrollState) {
      if (
        this.areaOptionsPagination.current_page <
        this.areaOptionsPagination.total_pages
      ) {
        this.areaOptionsPagination.current_page++;

        await this.getAreas();
        infScrollState.loaded();
      } else {
        infScrollState.loaded();
        infScrollState.complete();
      }
    },
    searchBuildings: debounce(function (search, loading) {
      loading(true);
      this.clearBuildingOptions();
      this.buildingNameSearch = search;
      this.getBuildings().then(() => {
        loading(false);
      });
    }, 300),
    buildingChanged(val) {
      // Update sync prop
      this.$emit("update:building", val);
    },
    clearBuildingOptions() {
      this.buildingNameSearch = "";
      this.buildingOptions = [];
      this.buildingOptionsPagination.current_page = 1;
      this.buildingOptionsPagination.total_pages = 1;
    },
    async handleBuildingInfiniteLoading(infScrollState) {
      if (
        this.buildingOptionsPagination.current_page <
        this.buildingOptionsPagination.total_pages
      ) {
        this.buildingOptionsPagination.current_page++;

        await this.getBuilding();
        infScrollState.loaded();
      } else {
        infScrollState.loaded();
        infScrollState.complete();
      }
    },
    streetInput: debounce(function () {
      let vm = this;
      // Emit event
      this.$emit("update:street", this.address.street);
      // Query from backend
      this.getStreets(this.address.street).then((data) => {
        let suggestions = vm.filterResults(data, vm.address.street, "name");
        vm.streetOptions = []; // Clear suggestions
        vm.streetOptions.push({ data: suggestions }); // push suggestions
      });
    }, 300),
    streetSelected(selected) {
      this.address.street = selected.item.name;
    },

    // ============================== API Related ==============================
    async getCountries() {
      try {
        let data = await this.$store.dispatch(
          "manageCountries/getAllCountries",
          { limit: 200 }
        );
        this.countryOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    async getStates(country) {
      try {
        let params = this.$cleanQueryParam({
          "country:id": country ? country : null,
          limit: 200
        });
        let data = await this.$store.dispatch(
          "manageStates/getAllStates",
          params
        );
        this.stateOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    async getCities({ id = "" } = {}) {
      try {
        let params = this.$cleanQueryParam({
          "state:id": this.address.state.id,
          "name[partial]": this.cityNameSearch.trim(),
          id: id,
          limit: 15,
          page: this.cityOptionsPagination.current_page
        });
        let data = await this.$store.dispatch(
          "manageCities/getAllCities",
          params
        );
        this.cityOptions.push(...data.data);
        this.cityOptionsPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        throw error;
      }
    },
    async getAreas({ id = "" } = {}) {
      try {
        let params = this.$cleanQueryParam({
          "city:id": this.address.city.id,
          "name[partial]": this.areaNameSearch.trim(),
          id: id,
          limit: 15,
          page: this.areaOptionsPagination.current_page
        });
        let data = await this.$store.dispatch(
          "manageAreas/getAllAreas",
          params
        );

        this.areaOptions.push(...data.data);
        this.areaOptionsPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        throw error;
      }
    },
    async getBuildings({ id = "" } = {}) {
      try {
        let params = this.$cleanQueryParam({
          "area:id": this.address.area.id,
          "name[partial]": this.buildingNameSearch.trim(),
          id: id,
          limit: 15,
          page: this.buildingOptionsPagination.current_page
        });
        let data = await this.$store.dispatch(
          "manageBuildings/getAllBuildings",
          params
        );
        this.buildingOptions.push(...data.data);
        this.buildingOptionsPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        throw error;
      }
    },
    async getStreets(name) {
      try {
        let data = await this.$store.dispatch("manageProperty/getStreets", {
          areaId: this.address.area.id,
          name: name
        });

        return data;
      } catch (error) {
        throw error;
      }
    },
    async generateGeocodeFromAddrDets() {
      try {
        let area = this.address.area.name;
        let street = this.address.street;
        let building = this.address.building?.name ?? "";

        let addr = String(`${building} ${street} ${area}`).trim();

        let data = await getGeocodes(addr);
        let coordination = data[0].geometry.location;
        if (coordination) {
          this.address.lat = coordination.lat;
          this.address.lng = coordination.lng;
        } else {
          throw "Unable to detect coordination from address details";
        }
      } catch (error) {
        this.$notify({
          group: "alert",
          title: "Generate Coordination",
          text: `${error}`
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
