import { geocodeAPI } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";

async function getGeocodes(addr) {
    const KEY = process.env.VUE_APP_GOOGLE_MAP_API;
    const query = new URLSearchParams({
        key: KEY,
        address: addr
    });

    const response = await fetch(`${geocodeAPI}?${query}`)
        .then((res) => res.json())
        .then((data) => data);

    return response.results;
}

export { getGeocodes };
